@use "../abstracts/index" as *;

.batching {
  height: calc(100vh - 4rem);
  max-height: 100vh;
  display: grid;
  grid-template-columns: 1fr 1fr;
  align-items: center;

  .introduce {
    margin: 2rem auto;
    width: 40vw;
  }

  .batching-monitor {
    display: flex;
    align-items: center;
    justify-content: center;
    width: auto;
  }
}

.features-menu {
  background: white;
  display: grid;
  grid-template-columns: auto auto auto auto auto auto;
  align-items: center;
  justify-content: space-evenly;
  height: 8rem;

  a {
    background: white;
    height: 100%;
    width: 10rem;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    color: $grey-500;
    text-transform: capitalize;

    svg {
      font-size: 3rem;
    }

    &:hover {
      background: $grey-100;
      color: $text-color;
    }
  }

  @media (max-width: 992px) {
    grid-template-columns: auto auto auto;
    height: 14rem;
  }
}
