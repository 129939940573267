@use "../abstracts/index" as *;

.btn {
  cursor: pointer;
  border: transparent;
  background: $primary-main;
  color: $white;
  border-radius: $border-radius;
  padding: 0.375rem 0.75rem;
  box-shadow: $shadow-1;
  transition: $transition;
  text-transform: capitalize;
  display: inline-block;

  &:hover {
    box-shadow: $shadow-2;
  }
}

// variant

.contained {
  color: $white;
}

.outlined {
  background: $white;
}

.text {
  border: none;
}

// color

.primary {
  background: $primary-main;
  &:hover {
    background: $primary-dark;
  }
}

.secondary {
  background: $secondary-main;
  &:hover {
    background: $secondary-dark;
  }
}

.error {
  background: $error-main;
  &:hover {
    background: $error-dark;
  }
}

.warning {
  background: $warning-main;
  &:hover {
    background: $warning-dark;
  }
}

.info {
  background: $info-main;
  &:hover {
    background: $info-dark;
  }
}

.success {
  background: $success-main;
  &:hover {
    background: $success-dark;
  }
}

.outlined-primary {
  color: $primary-main;
  border: 1px solid $primary-main;
  &:hover {
    color: $primary-dark;
    border: 1px solid $primary-dark;
  }
}

.outlined-secondary {
  color: $secondary-main;
  border: 1px solid $secondary-main;
  &:hover {
    color: $secondary-dark;
    border: 1px solid $secondary-dark;
  }
}

.outlined-error {
  color: $error-main;
  border: 1px solid $error-main;
  &:hover {
    color: $error-dark;
    border: 1px solid $error-dark;
  }
}

.outlined-warning {
  color: $warning-main;
  border: 1px solid $warning-main;
  &:hover {
    color: $warning-dark;
    border: 1px solid $warning-dark;
  }
}

.outlined-info {
  color: $info-main;
  border: 1px solid $info-main;
  &:hover {
    color: $info-dark;
    border: 1px solid $info-dark;
  }
}

.outlined-success {
  color: $success-main;
  border: 1px solid $success-main;
  &:hover {
    color: $success-dark;
    border: 1px solid $success-dark;
  }
}

// size

.small {
  font-size: 0.875rem;
}

.medium {
  font-size: 1rem;
}

.large {
  font-size: 1.125rem;
}

.btn-block {
  width: 100%;
}
