@use "../abstracts/index" as *;

.menu-item {
  font-size: 0.875rem;
  cursor: pointer;
  position: relative;
  margin: 0 1rem;
  border-radius: $border-radius;
  padding: 0.25rem 0.5rem;
  text-decoration: none;
  color: $blue-900;
  transition: $transition;
  &:hover {
    background: $grey-50;
  }

  @media screen and (max-width: 1024px) {
    margin: 1rem auto;
    width: 50%;
  }
}

.menu_active {
  background: $grey-50;
}

.menu-item_container {
  position: relative;
  .submenu {
    position: absolute;
    left: 0;
    bottom: -0.5rem;
    transform: translateY(95%);
    background: white;
    border-radius: 5px;
    // -webkit-box-shadow: 5px 5px 15px 5px rgba(0, 0, 0, 0.24);
    // box-shadow: 5px 5px 15px 5px rgba(0, 0, 0, 0.08);
    // box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px,
      rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;
    pointer-events: none;
    opacity: 0;
    transition: all ease-in-out 300ms;
  }
  .submenu_open {
    opacity: 1;
    pointer-events: initial;
    transform: translateY(100%);
  }
}

.submenu-item {
  width: 20rem;

  a {
    padding: 0.5rem 1rem;
    display: flex;
    flex-direction: column;
    text-decoration: none;
    color: $blue-900;
    transition: $transition;

    &:hover {
      background: $grey-50;
    }

    span {
      font-size: small;
      color: $blue-400;
      margin-bottom: 0.25rem;
    }
  }
}
