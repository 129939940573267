@use "../UIElements/UITheme" as *;

.alert {
  padding: 0.375rem 0.75rem;
  margin-bottom: 1rem;
  border-color: transparent;
  border-radius: $border-radius;
  text-align: center;
  letter-spacing: $letter-spacing;
}

.danger {
  color: $red-dark;
  background: $red-light;
}

.success {
  color: $green-dark;
  background: $green-light;
}
