@use "../UIElements/UITheme" as *;

.form-row {
  margin-bottom: 1rem;
}

.form-label {
  display: block;
  font-size: $small-text;
  margin-bottom: 0.5rem;
  text-transform: capitalize;
  letter-spacing: $letter-spacing;
}

.form-input {
  font: inherit;
  width: 100%;
  padding: 0.375rem 0.75rem;
  background: $background-color;
  border: 1px solid $grey-200;
  height: 35px;
}
