@use "../UIElements/UITheme" as *;

.navbar {
  background: white;
  height: 4.5rem;
  display: flex;
  align-items: center;
  position: sticky;
  top: 0;
  user-select: none;
  z-index: 5;
  // box-shadow: 0 1px 5px rgba(0, 0, 0, 0.15);
  // border-bottom: 1px solid rgb(211, 211, 211);
  justify-content: space-evenly;
  // justify-content: center;

  @media screen and (max-width: 1024px) {
    height: 4rem;
  }

  .menubar {
    // background-color: red;
    display: flex;
    align-items: center;

    .hamburger {
      margin-left: 20px;
      display: none;
      cursor: pointer;

      svg {
        font-size: 2rem;
        color: $blue-700;
      }

      @media screen and (max-width: 1024px) {
        display: inline-block;
      }
    }
  }

  .navbtn {
    // background-color: red;
    width: 70%;
    padding-right: 0.5rem;
    // width: 100%;
    // margin-right: 20px;
    display: flex;
    justify-content: space-between;

    @media screen and (max-width: 1024px) {
      display: none;
    }

    .navmenu {
      // margin-left: 50px;
      display: flex;
      align-items: center;
    }
  }

  .dashboard-menu {
    // background-color: red;
    // margin-right: 20px;
    display: flex;
    align-items: center;
    gap: 1rem;

    svg {
      font-size: 0.875rem;
      color: $blue-700;
    }
  }
}

.navside {
  transform: translateX(-100%);
  transition: all 0.3s ease-in-out;

  .navmobile {
    transform: translateX(0);
  }
}
