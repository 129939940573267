$color-fg-menu-item: #000;
$color-bg-menu-item: #d4d4d4;
$green: #009036;

$lightGray: #f7f7f7;
$darkGray: #5e6278;

// blue
$blue-50: #afc3ff;
$blue-100: #a0b6f3;
$blue-200: #91a9e8;
$blue-300: #839cdc;
$blue-400: #748fd0;
$blue-500: #6583c5;
$blue-600: #5676b9;
$blue-700: #4869ad;
$blue-800: #395ca2;
$blue-900: #2a4f96;

// orange
$orange-50: #f5ab77;
$orange-100: #f1a46e;
$orange-200: #ec9d65;
$orange-300: #e8955c;
$orange-400: #e38e53;
$orange-500: #df874b;
$orange-600: #da8042;
$orange-700: #d67839;
$orange-800: #d17130;
$orange-900: #cd6a27;

$blue-1: #eaf3fa;
$blue-2: #dbe9fa;
$blue-3: #cce0f8;
$lightBlue: #6d89cb;
$darkBlue: #2a4f96;

$blue-chartcolor: #008ffb;

$borderGray: #e4e6ef;
$textboxGray: #aca5b7;

$grey-50: #f0f4f8;
$grey-100: #d9e2ec;
$grey-200: #bcccdc;

// rest of the colors
$black: #222;
$white: #fff;
$red-light: #f8d7da;
$red-dark: #842029;
$green-light: #d1e7dd;
$green-dark: #0f5132;

$background-color: $grey-50;
$small-text: 0.875rem;
$border-radius: 0.25rem;
$letter-spacing: 1px;
$transition: 0.3s ease-in-out all;

$max-width: 1120px;
$fixed-width: 500px;
$fluid-width: 90vw;

// box shadow

$shadow-1: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);
$shadow-2: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
$shadow-3: 0 10px 15px -3px rgba(0, 0, 0, 0.1),
  0 4px 6px -2px rgba(0, 0, 0, 0.05);
$shadow-4: 0 20px 25px -5px rgba(0, 0, 0, 0.1),
  0 10px 10px -5px rgba(0, 0, 0, 0.04);

// h3 {
//   font-size: 1.953rem;
// }
