.blob {
  background: rgba(158, 240, 26, 1);
  border-radius: 50%;
  box-shadow: 0 0 0 0 rgba(158, 240, 26, 1);
  //   margin: 10px;
  //   padding: 10px;
  height: 15px;
  width: 15px;
  transform: scale(1);
  animation: pulse 3s infinite;
}

@keyframes pulse {
  0% {
    transform: scale(0.9);
    box-shadow: 0 0 0 0 rgba(158, 240, 26, 0.7);
  }

  70% {
    transform: scale(1);
    box-shadow: 0 0 0 20px rgba(158, 240, 26, 0);
  }

  100% {
    transform: scale(0.9);
    box-shadow: 0 0 0 0 rgba(158, 240, 26, 0);
  }
}
