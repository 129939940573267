@use "../UIElements/UITheme" as *;

.side-navigation {
  padding: 5rem 1rem;
  display: flex;
  height: 100%;
  flex-direction: column;
  // justify-content: space-around;

  .menu {
    display: flex;
    flex-direction: column;

    .home {
      margin-bottom: 1rem;
      background-color: #f7f9fb;
      border-radius: 5px;
      padding: 0.5rem 0.5rem;

      a {
        cursor: pointer;
        text-decoration: none;
        color: $darkGray;
        transition: all 0.3s;
        &:hover {
          background: #d4d4d4;
        }
      }
    }

    .submenu {
      margin-bottom: 1rem;
      background-color: #f7f9fb;
      display: flex;
      flex-direction: column;
      border-radius: 5px;
      padding: 0.5rem 0.5rem;
      color: $darkGray;

      .submenu-item {
        // background-color: #f7f9fb;
        display: flex;
        flex-direction: column;
        // margin: 1rem 0;
        margin-top: 0.5rem;
        margin-left: 0.5rem;

        a {
          cursor: pointer;
          text-decoration: none;
          color: $darkGray;
          transition: all 0.3s;
          margin: 0.5rem 0;
          &:hover {
            background: #d4d4d4;
          }
        }
      }
    }
  }

  .things-button {
    text-align: center;
  }
}
