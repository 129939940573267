@use "../abstracts/index" as *;

.logo {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  min-width: 250px;

  img {
    width: 64px;
    height: 64px;
  }

  h4 {
    color: $blue-700;
    font-weight: 500;
    margin-bottom: 0;
    span {
      color: $blue-50;
    }
  }
}
