@use "../shared/components/UIElements/UITheme" as *;

.register-page {
  display: grid;
  align-items: center;
  min-height: calc(100vh - 4.5rem);

  form {
    width: 90vw;
    // max-width: $fixed-width;
    background: $white;
    border-radius: $border-radius;
    box-shadow: $shadow-2;
    margin: 3rem auto;
    padding: 2rem 2.5rem;
    transition: $transition;

    border-top: 5px solid $blue-500;
    max-width: 400px;

    &:hover {
      box-shadow: $shadow-4;
    }

    h3 {
      text-align: center;
      margin-bottom: 1.38rem;
    }

    p {
      margin: 0;
      margin-top: 1rem;
      text-align: center;

      .member-btn {
        font: inherit;
        background: transparent;
        border: transparent;
        color: $blue-500;
        cursor: pointer;
        letter-spacing: $letter-spacing;
        padding: 1px 6px;
      }
    }
  }
}
