@use "../../shared/components/UIElements/UITheme" as *;

.container {
  background: $lightGray;
  height: 100%;
}

.card {
  display: flex;
  background: $blue-2;
  align-items: center;
  justify-content: space-between;

  @media screen and (max-width: 1024px) {
    flex-direction: column;
    gap: 0.5rem;
  }

  h3 {
    color: $darkBlue;
    font-size: 1rem;
    margin-left: 20px;
    font-weight: 400;
  }

  .flatPickr {
    font: inherit;
    background-color: white;
    color: $darkBlue;
    padding: 0.25rem 0.5rem;
    border: 1px solid $lightBlue;
    border-radius: 2px;
    cursor: pointer;
    // margin-right: 20px;
    //   box-shadow: 0 1px 5px rgba(0, 0, 0, 0.1);

    &:hover {
      border: 1px solid $darkGray;
    }

    &:focus {
      border: 1px solid $darkGray;
      outline: none;
    }
  }
}
