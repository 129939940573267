@use "../../../shared/components/UIElements/UITheme" as *;

.header {
  display: flex;
  justify-content: space-between;
  margin-bottom: 1rem;

  .title {
    display: flex;

    h2 {
      font-weight: 400;
      color: $blue-chartcolor;
    }

    .blob {
      align-self: center;
      margin-left: 0.5rem;
    }
  }

  .value {
    display: flex;

    h2 {
      font-weight: 400;
    }

    span {
      // background: #1d3557;
      background: #2a4f96;
      color: #f1faee;
      align-self: flex-end;
      margin-left: 0.25rem;
      padding: 0.2rem;
      border-radius: 5px;
      font-weight: 300;
    }
  }
}
