.landing {
  display: flex;
  height: calc(100vh - 4.5rem);
  color: #5e6278;
  background: #f7f7f7;
  flex-direction: row;

  @media screen and (max-width: 1024px) {
    flex-direction: column;
  }

  .landing-text {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    .banner {
      margin: 1rem;
      padding: 2rem;

      h1 {
        font-weight: 400;
        font-size: 2.3rem;
        margin-bottom: 1rem;
        text-align: center;

        span {
          color: #2a4f96;
          font-weight: 500;
        }
      }

      p {
        font-weight: 400;
        // line-height: 1.8rem;
        text-align: center;

        span {
          color: #cd6a27;
          font-weight: 600;
        }
      }

      .btn {
        text-align: center;
        margin-top: 1.5rem;
      }

      @media screen and (max-width: 1024px) {
        h1 {
          font-size: 1.5rem;
        }
      }
    }
  }

  .landing-image {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-end;

    img {
      width: 100%;
      height: auto;
    }
  }
}
